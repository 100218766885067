import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import BackgroundImage from 'gatsby-background-image'


const StyledSlideWrapper = styled.article`
  position: relative;
  height: 550px;
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  align-items: center;
  overflow: hidden;

  ${({theme}) => theme.mq.m} {
    padding-top: 0;
  }

  ${({theme}) => theme.mq.s} {
    height: 300px;
    padding-top: 0px;
    grid-template-columns: 60% 40%;
  }
`;

const StyledImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  background-size: cover;

  ${({theme}) => theme.mq.s} {
    flex-grow: 1;
  }
`;

const StyledContent = styled.div`
  color: ${({theme}) => theme.color.black};
  text-align: center;
  display: grid;
  justify-items: center;
  grid-gap: 1rem;
  text-transform: uppercase;
  padding: 2rem 3rem;

  h3 {
    font-size: 4rem;
    letter-spacing: 2px;
    font-weight: 700;
  }

  p {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  ${({theme}) => theme.mq.m} {

    h3 {
      font-size: 3rem;
      letter-spacing: 1px;
      font-weight: 700;
    }

    p {
      font-size: 1.7rem;
      margin-bottom: 2rem;
    }
  }

  ${({theme}) => theme.mq.s} {
    padding: 1rem 2rem;
    margin: 2rem 1rem;
    grid-gap: .5rem;
    min-height: 100px;

    h3 {
      font-size: 1.4rem;
      letter-spacing: 0px;
      font-weight: 700;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

const StyledButton = styled(Button)`
  ${({theme}) => theme.mq.s} {
    min-height: 27px;
    padding: .5rem;
  }
`;

const HeroSlide = ({image, textContent, anchor, color, background, id}) => {
  return (
    <StyledSlideWrapper key={id}>
      <StyledImage fluid={image.fluid}/>
      <StyledContent color={color} contentBox={background}>
        <h3>{textContent.main}</h3>
        <p>{textContent.minor}</p>
        <StyledButton
          key={id}
          component={anchor.isExternal ? 'a' : 'link'}
          to={anchor.isExternal ? anchor.url : `/${anchor.url}`}
        >
          CHECK
        </StyledButton>
      </StyledContent>
    </StyledSlideWrapper>
  );
};

HeroSlide.defaultProps = {
  image: {
    x: 50,
    y: 50,
  },
};

export default HeroSlide;
