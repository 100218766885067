import React, { useMemo} from 'react';
import Layout from 'components/layout/Layout';
import HeroSlider from 'components/HeroSlider';
import SecondarySlider from 'components/SecondarySlider';
import About from 'components/About';
import {graphql} from 'gatsby';
import SEO from "../components/Seo";

const aboutText = {
  main: 'We are a plus size model agency based in Warsaw, Poland. Working for many years with Polish and foreign brands, we have managed to create a team of great working plus size models.',
  quotation: 'We believe in the beauty and strength of women with curves. The fact that each of us can accept each other, caring for the beauty of the spirit and body.',
  author: 'Sara Czarna - ONMOVE founder',
};

const Home = ({data, location}) => {
  const heroSlides = useMemo(() => data.allDatoCmsHeroSlide.edges
    .sort((a, b) => ( a.node.position - b.node.position ))
    .map(({node}) => {
      const {
        backgroundImage,
        color,
        imagePositionX,
        imagePositionY,
        link: linkArr,
        id,
        textContent: textContentArr,
      } = node;
      const link = linkArr[ 0 ];
      const textContent = textContentArr[ 0 ];
      const isExternal = !!link.externalUrl;
      const url = isExternal ? link.externalUrl : `models/${link.internalUrl && link.internalUrl.slug}`;
      return {
        id,
        color,
        image: {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
          x: imagePositionX,
          y: imagePositionY,
        },
        anchor: {
          isExternal,
          url,
        },
        textContent: {
          main: textContent.main,
          minor: textContent.minor,
          position: textContent.textPosition,
        },
      };
    }), []);

  const secondarySlides = useMemo(() => data.allDatoCmsSecondarySlide.edges
    .sort((a, b) => ( a.node.position - b.node.position ))
    .map(({node}) => {
      const {
        id, textContent: textContentArr, backgroundImage, inverted, link: linkArr,
      } = node;
      const link = linkArr[ 0 ];
      const textContent = textContentArr[ 0 ];
      const isExternal = !!link.externalUrl;
      const url = isExternal ? link.externalUrl : `models/${link.internalUrl && link.internalUrl.slug}`;
      return {
        id,
        inverted,
        anchor: {
          isExternal, url,
        },
        textContent,
        imageUrl: backgroundImage.url,
        image: {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
        },
      };
    }), [ data.allDatoCmsSecondarySlide.edges ]);

  return (
    <Layout location={location}>
      <SEO title="HOME"/>
      <HeroSlider slides={heroSlides}/>
      <SecondarySlider slides={secondarySlides}/>
      {/*<InstagramFeed/>*/}
      <About text={aboutText} imageUrl="/about.jpg"/>
    </Layout>
  );
};

export default Home;

export const query = graphql`
    query AllHeroSlides {
        allDatoCmsHeroSlide {
            edges {
                node {
                    backgroundImage {
                        url
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 100) {
                                    src
                                    base64
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    presentationHeight
                                    aspectRatio
                                    sizes
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                    position
                    color
                    imagePositionX
                    imagePositionY
                    textContent {
                        textPosition
                        main
                        minor
                        background
                    }
                    link {
                        externalUrl
                        internalUrl {
                            slug
                        }
                    }
                    id
                }
            }
        }
        allDatoCmsSecondarySlide {
            edges {
                node {
                    id
                    position
                    inverted
                    textContent {
                        main
                        minor
                    }
                    link {
                        externalUrl
                        internalUrl {
                            slug
                        }
                    }
                    backgroundImage {
                        url
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 100) {
                                    src
                                    base64
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    presentationHeight
                                    aspectRatio
                                    sizes
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
