import React from 'react';
import SecondarySlide from './SecondarySlide';
import styled from "styled-components";
import Slider from "react-slick";

const settings = {
  autoplay: true,
  infinite: true,
  accessibility: false,
  arrows: false,
  draggable: false,
  fade: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplaySpeed: 4500,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const StyledSecondarySliderWrapper = styled.div`
  *:focus {
    outline: none !important;
  }
`;

const SecondarySlider = ({slides}) => (
  <StyledSecondarySliderWrapper>
    <Slider {...settings}>
      {slides.map(slide => <SecondarySlide key={slide.id} {...slide} />)}
    </Slider>
  </StyledSecondarySliderWrapper>
);

export default SecondarySlider;
