import React from 'react';
import styled from 'styled-components';
import HeroSlide from './HeroSlide';
import Slider from "react-slick";


const StyledSliderWrapper = styled.div`
  max-width: ${({theme}) => theme.container.max}px;
  margin: 0 auto;

  ${({theme}) => theme.mq.s} {
    margin-bottom: 20px;
  }

  *:focus {
    outline: none !important;
  }
`;

const settings = {
  autoplay: true,
  infinite: true,
  accessibility: false,
  arrows: false,
  draggable: false,
  fade: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplaySpeed: 4500,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const HeroSlider = ({slides}) => (
  <StyledSliderWrapper>
    <Slider {...settings}>
      {slides.map(slide => <HeroSlide key={slide.id} {...slide} anchor={JSON.parse(JSON.stringify(slide.anchor))} />)}
    </Slider>
  </StyledSliderWrapper>
);

export default HeroSlider;
