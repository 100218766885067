import React from 'react';
import styled from 'styled-components';
import repeatedText from "helpers/repeatedText";
import Button from "../Button";
import BackgroundImage from 'gatsby-background-image'

const StyledSlideWrapper = styled.article`
  position: relative;
  height: 690px;
  padding: 60px 0px;
  max-width: ${({theme}) => theme.container.l}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  align-items: center;
  overflow: hidden;

  ${({theme}) => theme.mq.m} {
    grid-template-columns: 45% 55%;
  }

  ${({theme}) => theme.mq.s} {
    height: 320px;
    padding: 20px 0px;
    margin: 20px 0px;
      grid-template-columns: 40% 60%;
    //display: flex;
    //flex-direction: column;
  }
`;

const StyledBackground = styled.p`
  font-size: 21px;
  color: #F5F2F2;
  letter-spacing: .95px;
  line-height: 30px;
  position: absolute;
  z-index: -1;
  text-align: justify;
  word-break: break-all;
  top: 60px;
  left: 0;
  width: 100%;
  height: 690px;
  overflow: hidden;

  ${({theme}) => theme.mq.s} {
    top: 0;
    height: calc(100vh - ${({theme}) => theme.navbarHeight.s}px);
  }
`;

const StyledImage = styled(BackgroundImage)`
  height: 80%;
  width: 70%;
  background-size: cover;

  ${({theme}) => theme.mq.m} {
    width: 80%;
  }

  ${({theme}) => theme.mq.s} {
    width: 85%;
    margin-bottom: 10%;
  }
`;

const StyledContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: grid;
    justify-items: center;
    grid-gap: 4rem;
    text-align: center;

    h3 {
      font-size: 4.5rem;
      font-weight: 900;
      letter-spacing: 1px;
    }

    p {
      font-size: 2rem;
      font-weight: 500;
      padding: 0 1rem .5rem 1rem;
      position: relative;

      &:before {
        display: block;
        content: '';
        height: 1px;
        width: 4rem;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -2rem);
        background-color: ${({theme}) => theme.color.black};

        ${({theme}) => theme.mq.s} {
          display: none;
        }
      }
    }

    ${({theme}) => theme.mq.m} {
      h3 {
        font-size: 3.5rem;
      }
    }

    ${({theme}) => theme.mq.s} {
      grid-gap: 2rem;
      padding: 20px 0 40px 0;

      h3 {
        font-size: 1.8rem;
      }
    }
  }
`;

const SecondarySlide = ({textContent, inverted, anchor, image}) => (
  <StyledSlideWrapper inverted={inverted}>
    <StyledContent>
      <div>
        <h3>NEW FACE</h3>
        <p>{textContent.minor}</p>
        <Button
          component={anchor.isExternal ? 'a' : 'link'}
          to={anchor.isExternal ? anchor.url : `/${anchor.url}`}
          color="dark"
          inverted
        >
          CHECK
        </Button>
      </div>
    </StyledContent>
    <StyledImage fluid={image.fluid}/>
    <StyledBackground>
      {repeatedText('ONMOVE PLUS SIZE MODEL MANAGEMENT ', 509, 50000)}
    </StyledBackground>
  </StyledSlideWrapper>
);


SecondarySlide.defaultProps = {
  inverted: false,
};

export default SecondarySlide;
